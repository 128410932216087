/* You can add global styles to this file, and also import other style files */
@use 'sass:math';
@import 'app/core//styles//fonts.scss';
@import'app/core/styles/colors.scss';
@import'app/core/styles/utilitys.scss';
@import'app/core/styles/text.scss';

html, body { height: 100%; color:$black; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; background-color: $gris1; overflow: hidden;}

*{
  box-sizing: border-box;
}
// Header
.mr-contain-header-box {
  height: 71px;
  width: 100%;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 45;
}

.mr-contain-header {
  background-color: $primary-white;
  height: 71px;
  width: 100%;
  top: 0px;
  p {
    margin: 0;
  }
}

// Display Flex
.mr-flex-row {
  display: flex;
  flex-direction: row;
}

.mr-flex-column {
  display: flex;
  flex-direction: column;
}

.mr-flex-flex-wrap {
  display: flex;
  flex-wrap: wrap;
}

.mr-flex-row-align-center {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.mr-flex-row-justific-center {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.mr-flex-row-justific-space-around {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.mr-flex-row-align-center-justific-space-around {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.mr-flex-column-align-center-justific-space-around {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
}

.mr-flex-column-align-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mr-flex-column-justify-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.mr-flex-column-justify-start {
  display: flex;
  flex-direction: column;
  justify-content: start;
}

.mr-flex-row-justific-flex-end {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.mr-flex-row-reverse-align-center {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}

.mr-flex-row-align-center-justific-left {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
}

.mr-flex-row-align-center-justific-right {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: right;
}

.mr-flex-row-align-center-justific-flex-end {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.mr-flex-row-align-center-justific-end {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
}

.mr-flex-row-align-center-justific-start {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
}

.mr-flex-row-align-center-justific-center {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.mr-flex-column-align-center-justific-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.mr-flex-column-align-flex-end-justific-center {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
}

.mr-flex-column-align-center-justific-flex-end {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}

.mr-flex-column-reverse-align-center-justific-flex-end {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: flex-end;
}

.mr-flex-column-justific-space-between {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.mr-flex-row-justific-space-between {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.mr-flex-row-align-inherit-justific-space-between {
  display: flex;
  justify-content: space-between;
  align-items: inherit;
  flex-direction: row;
}

.mr-flex-row-align-center-justific-space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}

.mr-flex-row-align-center-justific-space-evenly {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row;
}

.mr-flex-column-align-center-justific-space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}

// Imputs

.mr-mat-form-field-select {
  .mat-select-placeholder {
    color: $text-dark;
    position: relative;
    top: -3px;
  }
  .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
    color: $text-dark;
  }

  .mat-form-field.mat-focused .mat-form-field-label {
    color: $text-dark;
  }
  .mat-form-field-appearance-outline .mat-form-field-infix {
    padding: 3px 0 3px 0;
    display: flex;
  }
  .mat-form-field-appearance-outline .mat-form-field-label {
    top: 15px;
    margin-top: -0.25em;
  }
  .mat-form-field-appearance-outline .mat-form-field-outline-start,
  .mat-form-field-appearance-outline .mat-form-field-outline-end {
    border: none !important;
    background-color: $primary-white;
  }
  .mat-form-field-appearance-outline .mat-form-field-outline-gap {
    border: none !important;
    background-color: $primary-white;
  }
  .mat-form-field-appearance-outline .mat-form-field-outline-start {
    border-radius: 6px 0 0 6px;
    border-right-style: none;
  }
  .mat-form-field-appearance-outline .mat-form-field-outline-end {
    border-radius: 0 6px 6px 0;
  }
  .mat-form-field-appearance-outline .mat-form-field-outline-start {
    width: 15px !important;
  }
  mat-form-field {
    width: 100%;
    font-size: 18px;
    font-family: "Inter" !important;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
  }
  .mat-form-field-appearance-outline {
    border: none !important;
  }
  .mat-form-field-appearance-outline .mat-form-field-flex {
    padding: 0 15px;
    margin-top: -0.25em;
    position: relative;
  }
}

.mr-mat-form-field-date {
  .mat-date-range-input {
    display: block;
    width: 100%;
    position: relative;
    top: -3px;
  }
  .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
    color: $text-dark;
  }

  .mat-form-field.mat-focused .mat-form-field-label {
    color: $text-dark;
  }
  .mat-form-field-appearance-outline .mat-form-field-infix {
    padding: 3px 0 3px 0;
    display: flex;
  }
  .mat-form-field-appearance-outline .mat-form-field-label {
    top: 15px;
    margin-top: -0.25em;
  }
  .mat-form-field-appearance-outline .mat-form-field-outline-start,
  .mat-form-field-appearance-outline .mat-form-field-outline-end {
    border: none !important;
    background-color: $primary-white;
  }
  .mat-form-field-appearance-outline .mat-form-field-outline-gap {
    border: none !important;
    background-color: $primary-white;
  }
  .mat-form-field-appearance-outline .mat-form-field-outline-start {
    border-radius: 6px 0 0 6px;
    border-right-style: none;
  }
  .mat-form-field-appearance-outline .mat-form-field-outline-end {
    border-radius: 0 6px 6px 0;
  }
  .mat-form-field-appearance-outline .mat-form-field-outline-start {
    width: 15px !important;
  }
  mat-form-field {
    width: 100%;
    font-size: 18px;
    font-family: "Inter" !important;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
  }
  .mat-form-field-appearance-outline {
    border: none !important;
  }
  .mat-form-field-appearance-outline .mat-form-field-flex {
    padding: 0 15px;
    margin-top: -0.25em;
    position: relative;
  }
}

.mr-mat-form-field-input {
  .mr-mat-form-field-placeholder {
    margin-bottom: 0;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: $text-dark;
  }

  .mat-select-value {
    display: block;
    width: 100%;
    position: relative;
    top: -3px;
  }
  .mat-input-element {
    display: block;
    width: 100%;
    position: relative;
    top: -3px;
  }
  .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
    color: $text-dark;
  }

  .mat-form-field.mat-focused .mat-form-field-label {
    color: $text-dark;
  }
  .mat-form-field-appearance-outline .mat-form-field-infix {
    padding: 3px 0 3px 0;
    display: flex;
  }
  .mat-form-field-appearance-outline .mat-form-field-label {
    top: 15px;
    margin-top: -0.25em;
  }
  .mat-form-field-appearance-outline .mat-form-field-outline-start,
  .mat-form-field-appearance-outline .mat-form-field-outline-end {
    background-color: $primary-white;
  }
  .mat-form-field-appearance-outline .mat-form-field-outline-gap {
    background-color: $primary-white;
  }
  .mat-form-field-appearance-outline .mat-form-field-outline-start {
    border-radius: 6px 0 0 6px;
    border-right-style: none;
  }
  .mat-form-field-appearance-outline .mat-form-field-outline-end {
    border-radius: 0 6px 6px 0;
  }

  .mat-form-field-appearance-outline .mat-form-field-outline-start {
    width: 15px !important;
  }
  mat-form-field {
    width: 100%;
    font-size: 18px;
    font-family: "Inter" !important;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
  }
  .mat-form-field-appearance-outline {
    border: none !important;
  }
  .mat-form-field-appearance-outline .mat-form-field-flex {
    padding: 0 15px;
    margin-top: -0.25em;
    position: relative;
  }

  .mat-form-field-subscript-wrapper {
    font-size: 75%;
    line-height: 14px;
  }
  .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
    color: $primary-invalid !important;
  }
}

.mr-mat-form-field-input-multi-date {
  .mat-form-field-appearance-outline .mat-form-field-infix {
    display: flex !important;
    align-items: center;
    height:40px;
  }
}
.mr-mat-form-field-input-header.datepicker .mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 3px 0 3px 0;
  display: flex !important;
  align-items: center;
  height: 40px;
}
.mr-mat-form-field-input-header {
  .mat-form-field-wrapper {
    padding-bottom: 0em !important;
  }

  .mr-mat-form-field-placeholder {
    margin-bottom: 0;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: $text-dark;
  }

  .mat-input-element {
    display: block;
    width: 100%;
    position: relative;
    top: -3px;
  }
  .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
    color: $text-dark;
  }

  .mat-form-field.mat-focused .mat-form-field-label {
    color: $text-dark;
  }
  .mat-form-field-appearance-outline .mat-form-field-infix {
    padding: 3px 0 3px 0;
    display: flex !important;
    align-items: center;

  }

  .mat-form-field-appearance-outline .mat-form-field-infix.datepicker {
    padding: 3px 0 3px 0;
    display: flex !important;
    align-items: center;
    height: 40px;
  }
  .mat-form-field-appearance-outline .mat-form-field-label {
    top: 15px;
    margin-top: -0.25em;
  }
  .mat-form-field-appearance-outline .mat-form-field-outline-start,
  .mat-form-field-appearance-outline .mat-form-field-outline-end {
    background-color: $primary-white;
  }
  .mat-form-field-appearance-outline .mat-form-field-outline-gap {
    background-color: $primary-white;
  }
  .mat-form-field-appearance-outline .mat-form-field-outline-start {
    border-radius: 6px 0 0 6px;
    border-right-style: none;
  }
  .mat-form-field-appearance-outline .mat-form-field-outline-end {
    border-radius: 0 6px 6px 0;
  }

  .mat-form-field-appearance-outline .mat-form-field-outline-start {
    width: 15px !important;
  }
  mat-form-field {
    width: 100%;
    font-size: 18px;
    font-family: "Inter" !important;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
  }
  .mat-form-field-appearance-outline {
    border: none !important;
  }
  .mat-form-field-appearance-outline .mat-form-field-flex {
    padding: 0 15px;
    margin-top: -0.25em;
    position: relative;
  }
}

.mr-mat-form-field-textarea {
  mat-form-field {
    width: 100% !important;
    font-size: 18px;
    font-family: "Inter" !important;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
  }

  .mat-input-element {
    display: block;
    width: 100%;
    position: relative;
    top: -3px;
    height: 128px;
  }
}

::ng-deep .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: $primary-blue !important;
}
.mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: $primary-blue !important;
}

.mr-select-color-grey {
  .mat-form-field-outline-start,
  .mat-form-field-outline-gap,
  .mat-form-field-outline-end {
    background-color: $secondar-grey-F8F !important;
  }
}

// Button
.mr-button-general-outline {
  min-width: 148px;
  height: 37px;
  background-color: transparent;
  color: $primary-blue;
  border-radius: 6px;
}

.mr-button-general {
  min-width: 148px;
  height: 37px;
  background-color: $primary-blue;
  color: $primary-white !important;
  border-radius: 6px;
}
.mr-button-general-disabled {
  min-width: 148px;
  height: 37px;
  background-color: $primary-blue;
  color: $primary-white;
  opacity: 1;

  border-radius: 6px;
}

.mr-button-border-blue {
  min-width: 108px;
  height: 37px;
  border: 2px solid $primary-blue !important;
  color: $primary-blue;
  border-radius: 6px;
}
.mr-button-border-transparent {
  min-width: 108px;
  height: 37px;
  border: 2px transparent !important;
  color: $primary-blue;
  border-radius: 6px;
}

.mr-button-lith-blue {
  height: 24px;
  background: $primary-lith-blue;
  border-radius: 20px !important;
  .mat-button-wrapper {
    display: flex;
    align-items: center;
  }
  .mr-button-lith-blue-p {
    margin: 0;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: $primary-white;
  }
  .mr-button-lith-blue-icon {
    margin-left: 15px;
  }
}

// table
.mr-table-general {
  th.mat-header-cell {
    border: none !important;
  }
  background: $primary-white;
  border-radius: 10px;
  table {
    width: 100%;
    .mr-box-table-th {
      display: flex;
      justify-content: center;
      align-items: center;
      background: $primary-text-blue;
      height: 41px;
      border: 1px solid #d9d9d9;
      font-family: "Inter";
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      color: $primary-white;
    }
    .mr-box-table-td {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 41px;
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: $text-dark-blue;
    }
  }
}
table.mat-table {
  padding: 0 10px;
}

table.mat-table {
  td {
    border-bottom: 2px solid gainsboro !important;
  }
}
th.mat-header-cell:first-of-type,
td.mat-cell:first-of-type,
td.mat-footer-cell:first-of-type {
  padding-left: 0px !important;
}
th.mat-header-cell:last-of-type,
td.mat-cell:last-of-type,
td.mat-footer-cell:last-of-type {
  padding-right: 0px !important;
}
// decoradores
.mr-decoration-a {
  text-decoration: underline;
}

.mr-decoration-curosr-pinter {
  cursor: pointer;
}

// mat menu
.mat-menu-content:not(:empty) {
  padding: 2px 0 !important;
  .mat-menu-item {
    padding: 0 40px;
  }
}

.input-experimental {
  mat-form-field.mat-form-field.mat-form-field-appearance-outline
    > div.mat-form-field-wrapper
    > div.mat-form-field-flex
    > div.mat-form-field-infix {
    padding: 0.4em 0px;
  }
  mat-form-field.mat-form-field.mat-form-field-appearance-outline
    > div.mat-form-field-wrapper
    > div.mat-form-field-flex
    > div.mat-form-field-infix
    > span.mat-form-field-label-wrapper {
    top: -1.5em;
  }

  .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
    transform: translateY(-24px) scale(1);
    width: 133.33333%;
  }
  .mat-form-field-label-wrapper {
    padding-top: 1.84375em;
  }
}

// mat drawer
.mat-drawer-content {
  height: 100vh !important;
  // overflow: auto;
}

.mat-drawer-inner-container {
  background-color: $text-dark;
}

.mat-drawer {
  z-index: 50 !important;
  width: 295px;
  position: fixed !important;
  // height: -webkit-fill-available;
}

// snackbar
.mr-snackbar {
  width: 344px !important;
  height: 48px !important;
  border-radius: 10px !important;
  background-color: $primary-blue;
  display: flex !important;
  align-items: center !important;
  margin-bottom: 105px !important;
  justify-content: center !important;
  font-family: "Inter" !important;
  font-size: 16px !important;
  font-weight: normal !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: normal !important;
  letter-spacing: normal !important;
  text-align: center !important;
  color: $primary-white !important;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: $primary-lith-blue;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: $primary-lith-blue;
}

//mat-tab
.mr-tab-nav-general {
  .mat-tab-nav-bar,
  .mat-tab-header {
    border-bottom: none;
    padding-bottom: 48px;
  }
}

.mr-mat-tab-label-on {
  opacity: 1 !important;
  margin: 0 !important;
  color: $primary-lith-blue;
}
.mr-mat-tab-label-off {
  opacity: 0.6 !important;
  margin: 0 !important;
  color: $text-gray-525;
}

.mat-tab-label {
  opacity: 1 !important;
}

.mat-tab-group.mat-primary .mat-ink-bar {
  background-color: $primary-blue !important;
}

.mr-nav-border {
  .mat-tab-labels {
    border-bottom: 2px solid #f3f3f3 !important;
  }
}

// .mat-drawer {
//   position: sticky !important;
// }
// .mr-contain-menu-body-scroll::-webkit-scrollbar {
//     display: none;
//    }

// mat checkbox
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: $primary-secon-ligth-blue !important;
}

// mat-chip

mat-chip {
  background-color: $primary-secon-ligth-blue !important;
  color: $primary-white !important;
}

// mat-simple
.mat-simple-snackbar {
  display: flex;
  justify-content: center !important;
  align-items: center;
  line-height: 20px;
  text-align: center;
  opacity: 1;
}

// mat-accordion general
.mr-accordion {
  &-general {
    & .mat-expansion-panel {
      margin-bottom: 32px;
      &-header {
        padding: 5px 60px 16px;
        &-title {
          font-family: "Inter";
          font-style: normal;
          font-weight: 600;
          font-size: 24px;
          color: $text-dark;
          line-height: 35px;
        }
      }
    }
  }
}
// mat-expansion-panel
.mr-certification-detail {
  .mat-expansion-panel-header {
    padding: 0 60px !important;
  }
  .mat-expansion-panel-body {
    padding: 0px 60px 16px;
  }
}

// progress

.mr-create-certification {
  mat-progress-bar {
    border-radius: 20px !important;
    height: 8px !important;
  }

  .mat-progress-bar .mat-progress-bar-fill::after {
    border-radius: 20px !important;
    height: 7px !important;
    background-color: $primary-lith-blue;
  }
}

:root {
  --animate-duration: 0.7s;
  --animate-delay: 0.7s;
  --animate-repeat: 1;
}

.animate__animated {
  -webkit-animation-duration: 0.7s;
  animation-duration: 0.7s;
  -webkit-animation-duration: var(--animate-duration);
  animation-duration: var(--animate-duration);
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@keyframes slideInRight {
  from {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.animate__slideInRight {
  -webkit-animation-name: slideInRight;
  animation-name: slideInRight;
}

.mr-pointer {
  cursor: pointer;
}

.w-100 {
  width: 100% !important;
}

.mr-map-modal {
  mat-dialog-container {
    padding: 0 !important;
  }
}

.mat-chip-list-wrapper {
  width: 100%;
}

$min:15;
$max:120;
$steo:2;

@mixin generate-font-sizes($min, $max, $steo) {
  @for $i from $min-size through $max-size {
    @if $i % $step == 0 {
      .textfont-#{$i} {
        font-size: #{$i}px;
      }
    }
  }
}
